
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { ElTable } from "element-plus";
import moment from "moment";
import JwtService from "@/core/services/JwtService";
import { Search } from "@element-plus/icons-vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Modal } from "bootstrap";
import { result } from "lodash";

export default defineComponent({
  name: "apps-create-purchase-invoice",
  directives: { mask },
  components: {},
  methods: {},

  setup(props, { emit }) {
    const store = useStore();
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const grnDetails = ref<Array<any>>([]);
    const tnListData = ref<Array<TransportList>>([]);
    const inward_type_data = ref([]);
    const supplier_data = ref([]);
    const branch_data = ref([]);
    const consignee_branch_data = ref([]);
    const consignee_data = ref([]);
    const yes_no_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<any[]>([]);

    const multipleTableTnRef = ref<InstanceType<typeof ElTable>>();
    const multipleTnSelection = ref<TransportList[]>([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();
    const dialogSelectProductTableVisible = ref(false);
    const addPlanningIndentRef = ref<null | HTMLElement>(null);

    const incotermData = ref([]);
    const paymentTermData = ref([]);
    const pendingGrnData = ref([]);
    const grnProductList = ref([]);
    const productData = ref({});
    const resultListData = ref([]);
    const invoiceChargesList:any = ref([]);

    interface TransportList {
      stock_transport_note_id: string;
      stock_transport_note_no: string;
      vehicle_no: string;
      lr_no: string;
      lr_date: string;
      driver_name: string;
      driver_mobile: string;
    }

    const formData = ref({
      supplier_select: "",
      supplier_branch_select: "",
      grn_select: "",
      invoice_received_yn: 0,
      tc_received_yn: 0,
      bill_off_loading_received_yn: 0,
      weighbridge_splip_yn: 0,
      incoterm: "",
      payment_term: "",

      invoice_date: "",
      invoice_no: "",
      dc_no: "",
      dc_date: "",
    });

    const rules = ref({
      supplier_select: [
        {
          required: true,
          message: "Supplier is required",
          trigger: "change",
        },
      ],
      supplier_branch_select: [
        {
          required: true,
          message: "Supplier Branch is required",
          trigger: "change",
        },
      ],
      grn_select: [
        {
          required: true,
          message: "GRN No is required",
          trigger: "change",
        },
      ],
      invoice_received_yn: [
        {
          required: true,
          message: "Invoice Received is required",
          trigger: "change",
        },
      ],
      tc_received_yn: [
        {
          required: true,
          message: "TC Received is required",
          trigger: "change",
        },
      ],
      bill_off_loading_received_yn: [
        {
          required: true,
          message: "Bill Of Loading Received is required",
          trigger: "change",
        },
      ],
      weighbridge_splip_yn: [
        {
          required: true,
          message: "Bill Of Loading Received is required",
          trigger: "change",
        },
      ],
      incoterm: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",
        },
      ],
      payment_term: [
        {
          required: true,
          message: "Payment Term is required",
          trigger: "change",
        },
      ],
      invoice_date: [
        {
          required: true,
          message: "Invoice Date is required",
          trigger: "change",
        },
      ],
      invoice_no: [
        {
          required: true,
          message: "Invoice No is required",
          trigger: "change",
        },
      ],
    });


    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBranchList() {
      let values = {
        company_id: formData.value["supplier_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          consignee_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const gettIncotermData = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_PRODUCT_INCOTERM)
          .then(({ data }) => {
            incotermData.value = data;
            loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const getPaymentTerms = async () => {
      try {
        await store
          .dispatch(Actions.CUST_GET_PAYMENT_TERM)
          .then(({ data }) => {
            debugger;
            paymentTermData.value = data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function getPendingGrntList() {
      let values = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_branch_select"],
      };
      try {
        await store
          .dispatch(ActionsFi.CUST_GET_PENDING_GRN_LIST, values)
          .then(({ data }) => {
            debugger;
            pendingGrnData.value = data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    }

    async function getGrnProuctList() {
      let values = { grn_id: formData.value.grn_select };
      try {
        await store
          .dispatch(ActionsFi.CUST_GET_GRN_DETAILS, values)
          .then(({ data }) => {
            productData.value = data;
            formData.value.invoice_no = data?.invoice_no;
            formData.value.invoice_date = data?.invoice_date
            formData.value.dc_no = data?.dc_no
            formData.value.dc_date = data?.dc_date
            formData.value.incoterm = data?.incoterm_id
            formData.value.payment_term = data?.payment_term_id
            resultListData.value = data?.result_list;
            let datawithExtrafields = data?.result_list.map((item) => {
              return { ...item, product_rate:item.pi_rate, remark: "" };
            });
            grnProductList.value = datawithExtrafields.concat(
              invoiceChargesList.value
            );
            console.log('grnList', grnProductList)
            setStaticFiledData()
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    }

    const getProductChargeList = async() => {
      try {
        await store
          .dispatch(ActionsFi.CUST_GET_PURCHASE_INVOICE_CHARGES)
          .then(({ data }) => {
            debugger;
            invoiceChargesList.value = data;
            console.log('invoice charges list', invoiceChargesList.value)
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    }

    const setCompanyData = async (data) => {
      let user = JSON.parse(JwtService.getToken());

      if (grnProductList.value?.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select GRN number.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      for (let row of grnProductList.value) {
        if (!row['isStatic'] && !row["product_rate"]) {
          Swal.fire({
            text: "Please enter product rate.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loading.value = false;
          return;
        }
      }

      let invoiceDetails: any = [];
      grnProductList.value.map((data: any) => {
        if(!data.isStatic){
          invoiceDetails.push({
            grn_detail_id: data?.grn_detail_id,
          })
        }
      });

      let requestData = {
        user_id: user?.user_id,
        supplier_company_id: formData.value.supplier_select,
        supplier_company_branch_id: formData.value.supplier_branch_select,
        grn_id: formData.value.grn_select,
        invoice_no: formData.value.invoice_no,
        invoice_date: moment(data.invoice_date).format("YYYY-MM-DD"),
        dc_no: formData.value.dc_no,
        dc_date: moment(data.dc_date).format("YYYY-MM-DD"),
        invoice_received_yn: formData.value.invoice_received_yn,
        tc_received_yn: formData.value.tc_received_yn,
        bill_of_loading_received_yn:
          formData.value.bill_off_loading_received_yn,
        weighbridge_slip_yn: formData.value.weighbridge_splip_yn,
        incoterm_id: formData.value.incoterm,
        payment_term_id: formData.value.payment_term,
        invoice_details: invoiceDetails,
        charge_data: invoiceChargesList.value
      };

      console.log("db_data", requestData);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_PURCHASE_INVOICE, requestData)
          .then(({ data, is_error, status, message }) => {
            loading.value = false;
            if (data?.pi_no && status !== 500){
            setTimeout(() => {
              Swal.fire({
                html: ""+
                      data?.pi_no+
                      "<br>Purchase invoice created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-purchase-invoice-listing" });
              });
            }, 2000);
            }else if(data == "" && is_error == false && status == 500){
              Swal.fire({
                  text: message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-purchase-invoice-listing" });
                });
          }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    }


    const setStaticFiledData = () => {
        let productAmountSubTotal = 0;
        let igstSubTotal = 0;
        let cgstSubTotal = 0;
        let sgstSubTotal = 0;
        let grandSubTotal = 0;
        let productAmountTotal = 0;
        let igstTotal = 0;
        let cgstTotal = 0;
        let sgstTotal = 0;
        let grandTotal = 0;
        let round = 0;
        let roundUp = 0;

      invoiceChargesList.value.map((row)=>{
        row.charge_amount= '',
        row.igst_amount= '',
        row.cgst_amount='',
        row.sgst_amount='',
        row.charge_total_amount= ''
      });

      resultListData.value.map((data: any) => {
          productAmountSubTotal = parseFloat(data?.product_amount) + productAmountSubTotal;
          grandSubTotal = parseFloat(data?.product_grand_total) + grandSubTotal;
          if(productData.value['igst_yn'] === 1){
            igstSubTotal = parseFloat(data?.igst_amount) + igstSubTotal;
          }else{
            cgstSubTotal = parseFloat(data?.cgst_amount) + cgstSubTotal;
            sgstSubTotal = parseFloat(data?.sgst_amount) + sgstSubTotal;
          }
       });

      invoiceChargesList.value.map((row) => {
           let productAmount = row?.charge_amount ? row.charge_amount : '0'
           let grandAount =  row?.charge_total_amount ? row.charge_total_amount : '0'
           
           productAmountTotal = parseFloat(productAmount) + productAmountTotal;
           grandTotal = parseFloat(grandAount) + grandTotal;
           if(productData.value['igst_yn'] === 1){
            let igstAmount =  row?.igst_amount ? row.igst_amount : '0'
            igstTotal = parseFloat(igstAmount) + igstTotal;
           }else{
            let cgstAmount =  row?.cgst_amount ? row.cgst_amount : '0';
            let sgstAmount = row?.sgst_amount ? row.sgst_amount : '0';
            cgstTotal = parseFloat(cgstAmount) + cgstTotal;
            sgstTotal = parseFloat(sgstAmount) + sgstTotal;
           }
          
      });
       
      round = Math.round(grandTotal + grandSubTotal);
      invoiceChargesList.value.map((row)=> {
          if(row?.charge_id === 100){
           row.charge_amount = productAmountSubTotal.toString();
           row.charge_total_amount = grandSubTotal.toString();
           if(productData.value['igst_yn'] === 1){
            row.igst_amount  = igstSubTotal.toString();
           }else{
            row.cgst_amount = cgstTotal.toString();
            row.sgst_amount = sgstTotal.toString();
           }
          }
          // else if(row.charge_id === 6){
          //   row.charge_amount = grandSubTotal.toString()
          // }
          else if(row.charge_id === 101){
            row.charge_amount = (productAmountTotal+ productAmountSubTotal).toString();
            row.charge_total_amount = (grandTotal + grandSubTotal).toString();
            if(productData.value['igst_yn'] === 1){
              row.igst_amount = (igstTotal + igstSubTotal).toString();
            }else{
              row.cgst_amount = (cgstTotal + cgstSubTotal).toString();
              row.sgst_amount = (sgstTotal + sgstSubTotal).toString();
            }
          }
          else if(row.charge_id === 102){
            roundUp = grandTotal + grandSubTotal - round
            row.charge_total_amount = parseFloat(roundUp.toString()).toFixed(2)
         }

          else if(row.charge_id === 103){
            row.charge_total_amount = parseFloat((round).toString()).toFixed(2);
          }
      })
    }

    const calculateStaticTableFields = (editedRow, column_name) => {
      if(editedRow[column_name] === ''){
          editedRow.charge_amount = '';
          editedRow.igst_amount = '';
          editedRow.cgst_amount = '';
          editedRow.sgst_amount = '';
          editedRow.charge_total_amount = '';
      }
        else if(!(/^\d*\.?\d*$/.test(editedRow[column_name]))){
         editedRow[column_name] = editedRow[column_name].slice(0, -1);;
         return;
      }
      else if([6].includes(editedRow.charge_id)){
        return;
      }
            
      console.log('static selected row',editedRow)
      let calculatedValue = 0;
      let productAmountColumn = parseFloat(editedRow.charge_amount ? editedRow.charge_amount : '0');
      let igstAmountColumn = parseFloat(editedRow.igst_amount);
      let productGrandTotalColumn =  parseFloat(editedRow.charge_total_amount);

      if(productData.value['igst_yn'] === 1){
        let igst  = (productAmountColumn * 18)/100;
        calculatedValue =  productAmountColumn + igst;
        editedRow.igst_amount = igst.toString();
      }else{
        let cgst = (productAmountColumn * 0.9)/100;
        let sgst = (productAmountColumn * 0.9)/100;
        calculatedValue =  productAmountColumn + cgst + sgst;
        editedRow.cgst_amount = cgst.toString();
        editedRow.sgst_amount = sgst.toString();
      }

      editedRow.charge_total_amount = calculatedValue.toString();

      let chargeAmount = 0;
      let igstChargeAmount = 0;
      let cgstChargeAmount = 0;
      let sgstChargeAmount  = 0;
      let chargeTotalAmount = 0;
      let roundUp = 0;
      let round = 0;
        for(let row of invoiceChargesList.value){
          if([6, 101, 102, 103].includes(row.charge_id)){
            continue;
          }
            let chargeAmountVal = row.charge_amount ? row.charge_amount : '0';
            let chargeTotalAmountVal = row.charge_total_amount ? row.charge_total_amount : '0';
            chargeAmount = parseFloat(chargeAmountVal) + chargeAmount;
            chargeTotalAmount = parseFloat(chargeTotalAmountVal) + chargeTotalAmount;
            if(productData.value['igst_yn'] === 1){
              let igstAmountVal = row.igst_amount ? row.igst_amount : '0';
              igstChargeAmount = parseFloat(igstAmountVal) + igstChargeAmount;
            }else{
              let cgstAmount = row.cgst_amount ? row.cgst_amount : '0';
              let sgstAmount = row.sgst_amount ? row.sgst_amount : '0';
              cgstChargeAmount = parseFloat(cgstAmount) + cgstChargeAmount;
              sgstChargeAmount = parseFloat(sgstAmount) + sgstChargeAmount;
            }
        }


        invoiceChargesList.value.map((row) => {
         if(row.charge_id === 101){
           row.charge_amount = chargeAmount;
           row.charge_total_amount = parseFloat(chargeTotalAmount.toString()).toFixed(2);
           if(productData.value['igst_yn'] === 1){
            row.igst_amount = igstChargeAmount;
           }else{
            row.cgst_amount = cgstChargeAmount;
            row.sgst_amount = sgstChargeAmount;
           }
           let toatlCharge  = chargeTotalAmount;
          round = Math.round(toatlCharge);
         }
          else if(row.charge_id === 102){
            roundUp = chargeTotalAmount-round
            row.charge_total_amount = parseFloat(roundUp.toString()).toFixed(2)
         }
         else if(row.charge_id === 103){
           row.charge_total_amount = parseFloat(round.toString()).toFixed(2)
         }
     })

    }

  

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Purchase Invoice", []);
      await getProductChargeList();
      await gettIncotermData();
      await getPaymentTerms();
    });

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      getCompanyList,
      supplier_data,
      addPlanningIndentRef,
      consignee_data,
      formRef,
      loading,
      dialogSelectProductTableVisible,
      addCompanyModalRef,
      uom_data,
      tnListData,
      inward_type_data,
      multipleTableRef,
      multipleSelection,
      multipleTableTnRef,
      yes_no_data,
      branch_data,
      multipleTnSelection,
      router,
      getBranchList,
      consignee_branch_data,
      grnDetails,

      incotermData,
      paymentTermData,
      getPendingGrntList,
      pendingGrnData,
      productData,
      getGrnProuctList,
      grnProductList,
      calculateStaticTableFields
    };
  },
});
